import axios from "axios";
import Cookies from "js-cookie";
import { applicationTypes, FeatureFlagObject } from "../pages/FeatureFlagPage";

const BASE_URL = `https://dcx-featureflags-${process.env.REACT_APP_ENV}.${process.env.REACT_APP_AWS}0002.stateauto.com`;


export const PATH = {
  FF: "featureflags",
  ADD_FF: "addfeatureflags",
  DELETE_FF: "deleteflag",
  UPDATE_FF: "updateflags",
  ASSOCIATES: "associates",
  DELETE_ASSOCIATES: "removeassociate",
  ADD_ASSOCIATES: "addassociate",
};

export interface Response<T> {
  data: T;
  errors: string[];
  hasErrors: boolean;
}

export const retrieveFeatureFlags = (applicationType: applicationTypes) => {
  return axios.get<Response<FeatureFlagObject[]>>(
    `${BASE_URL}/${applicationType}/${PATH.FF}`,
    {
      headers: {
        Authorization: Cookies.get("userToken"),
      },
    }
  );
};

export const updateFeatureFlag = (
  applicationType: applicationTypes,
  payload: any
) => {
  return axios.patch<any>(
    `${BASE_URL}/${applicationType}/${PATH.FF}`,
    JSON.stringify({ ...payload }),
    {
      headers: {
        Authorization: Cookies.get("userToken"),
      },
    }
  );
};

export const removeFeatureFlag = (
  applicationType: applicationTypes,
  payload: any
) => {
  return axios.delete<any>(`${BASE_URL}/${applicationType}/${PATH.FF}`, {
    headers: {
      Authorization: Cookies.get("userToken"),
    },
    data: {
      ...payload,
    },
  });
};

export const addFeatureFlag = (
  applicationType: applicationTypes,
  payload: any
) => {
  return axios.post<any>(
    `${BASE_URL}/${applicationType}/${PATH.FF}`,
    JSON.stringify({ ...payload }),
    {
      headers: {
        Authorization: Cookies.get("userToken"),
      },
    }
  );
};

export const retrieveAssociates = () => {
  return axios.get<Response<any>>(`${BASE_URL}/${PATH.ASSOCIATES}`, {
    headers: {
      Authorization: Cookies.get("userToken"),
    },
  });
};

export const removeAssociate = (payload: any) => {
  return axios.delete<any>(`${BASE_URL}/${PATH.ASSOCIATES}`, {
    headers: {
      Authorization: Cookies.get("userToken"),
    },
    data: {
      ...payload,
    },
  });
};

export const addAssociate = (payload: any) => {
  return axios.post<any>(
    `${BASE_URL}/${PATH.ASSOCIATES}`,
    JSON.stringify({ ...payload }),
    {
      headers: {
        Authorization: Cookies.get("userToken"),
      },
    }
  );
};
export {};
