import { SAAlert, SABox, SACard, SAText } from "@saux/design-system-react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { AddAssociateModal } from "../components/AddAssociateModal/AddAssociateModal";
import { CardContainer } from "../components/CardContainer/CardContainer";
import { RemoveAssociateModal } from "../components/RemoveAssociateModal/RemoveAssociateModal";
import { retrieveAssociates } from "../services";

const SAContainerBox = styled(SABox)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;
const SAContainerCard = styled(SACard)`
  display: flex;
  width: 100%;
  max-width: 1000px;
`;
export const AssociatePage = () => {
  const [currentAssociate, setCurrentAssociate] = useState<any | null>(null);
  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [successText, setSuccessText] = useState<string>("");
  const [failureText, setFailureText] = useState<string>("");
  const [hasErrors, setHasErrors] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [associates, setAssociates] = useState([
    { email_id: "", username: "" },
  ]);

  const getData = async () => {
    setLoading(true);
    try {
      const fetchedData = await retrieveAssociates();
      if (fetchedData.data.hasErrors) {
        setHasErrors(true);
      } else {
        setAssociates(fetchedData.data.data);
        setHasErrors(false);
      }
    } catch (err) {
      setHasErrors(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = ["Email", "Emp ID"];
  const data = associates.sort((a, b) =>
    a.email_id.toLowerCase() > b.email_id.toLowerCase() ? 1 : -1
  );
  const tableData = data.map((associate) => {
    return [associate.email_id, associate.username];
  });
  const onRemoveClick = (featureFlagRow: string[]) => {
    setCurrentAssociate(
      associates.find((associate) => associate.email_id === featureFlagRow[0])
    );
    setShowRemoveModal(true);
  };

  const closeRemoveModal = () => {
    setShowRemoveModal(false);
  };

  const checkIsEmailExisting = (email: string) =>
    !!associates.find(
      (associate) => associate.email_id.toLowerCase() === email.toLowerCase()
    );

  const checkIsEmpIdExisting = (empId: string) =>
    !!associates.find(
      (associate) => associate?.username?.toLowerCase() === empId.toLowerCase()
    );

  return (
    <>
      {(successText || failureText) && (
        <SAAlert
          severity={successText ? "success" : "error"}
          icon={false}
          closeable
          onClose={() => {
            setFailureText("");
            setSuccessText("");
          }}
        >
          {successText ? successText : failureText}
        </SAAlert>
      )}
      {!hasErrors && !loading && (
        <CardContainer
          title={"Associate"}
          buttonLabel={"Add Associate"}
          onButtonClick={() => setShowAddModal(true)}
          columns={columns}
          data={tableData}
          onRowClick={() => {}}
          onRemoveClick={onRemoveClick}
        />
      )}
      {showRemoveModal && (
        <RemoveAssociateModal
          associate={currentAssociate}
          closeHandler={closeRemoveModal}
          setSuccessText={setSuccessText}
          setFailureText={setFailureText}
          getData={getData}
        />
      )}
      {showAddModal && (
        <AddAssociateModal
          closeHandler={() => setShowAddModal(false)}
          setSuccessText={setSuccessText}
          setFailureText={setFailureText}
          checkIsEmailExisting={checkIsEmailExisting}
          checkIsEmpIdExisting={checkIsEmpIdExisting}
          getData={getData}
        />
      )}
      {hasErrors && (
        <SAContainerBox padding={"large"}>
          <SAContainerCard variant="standard" title="Error">
            <SACard variant="sub">
              <SAText text="Failed to retrieve associates, please try again later." />
            </SACard>
          </SAContainerCard>
        </SAContainerBox>
      )}
    </>
  );
};
