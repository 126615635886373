import {
  SABox,
  SAButton,
  SACard,
  SAColumns,
  SAIcon,
  SAIcons,
  SAIconSize,
  SAInput,
  SAModal,
  SARadio,
  SARadioGroup,
  SAText,
} from "@saux/design-system-react";
import styled from "styled-components";
import { Dispatch, SetStateAction, useState } from "react";
import { removeFeatureFlag } from "../../services";
import { applicationTypes } from "../../pages/FeatureFlagPage";

const SAContainerBox = styled(SABox)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;
const SAInnerBox = styled(SABox)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const SATextBox = styled(SABox)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SAContainerCard = styled(SACard)`
  width: 100%;
  max-width: 700px;
  display: flex;
`;

const Columns = styled(SAColumns)`
  flex-grow: 1;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  div {
    flex-grow: 0;
  }
`;

export interface RemoveFeatureFlagModalProps {
  featureFlag: any;
  closeHandler: () => void;
  setSuccessText: Dispatch<SetStateAction<string>>;
  setFailureText: Dispatch<SetStateAction<string>>;
  applicationType: applicationTypes;
  getData: () => void;
}

export const RemoveFeatureFlagModal = ({
  featureFlag,
  closeHandler,
  setSuccessText,
  setFailureText,
  applicationType,
  getData,
}: RemoveFeatureFlagModalProps) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const jsxElement: JSX.Element = (
    <Columns spacing="small">
      <SAIcon
        icon={SAIcons.x}
        size={SAIconSize.medium}
        colorVariant="dark"
        onClick={closeHandler}
      />
    </Columns>
  );

  const onSubmit = async () => {
    setSuccessText("");
    setFailureText("");
    setIsSubmitting(true);
    const flag = featureFlag.flag_name;
    try {
      const response = await removeFeatureFlag(applicationType, { flag });
      if (!response.data.hasErrors) {
        setSuccessText(
          `Successfully Removed Feature Flag ${featureFlag.flag_name}`
        );
      } else {
        setFailureText("Failed to remove");
      }
    } catch (err) {
      setFailureText("Failed to remove");
    }
    getData();
    setIsSubmitting(false);
    closeHandler();
  };

  return (
    <SAModal onClickHandler={() => {}}>
      <SAContainerBox padding={"large"}>
        <SAContainerCard
          variant="standard"
          title={`Remove Feature Flag: ${featureFlag.flag_name}`}
          jsxElement={jsxElement}
        >
          <SAInnerBox>
            <SAColumns
              columns={{
                sm: [4, 8, 8, 2, 2],
                xs: [12, 12, 12, 12, 12],
              }}
              spacing={{
                xs: "small",
              }}
            >
              <SARadioGroup
                id="TrueFalseSelector"
                label="Status"
                variant="standard"
                direction="horizontal"
                value={`${featureFlag.enabled}`}
              >
                <SARadio
                  value="true"
                  label="True"
                  variant="standard"
                  disabled
                />
                <SARadio
                  value="false"
                  label="False"
                  variant="standard"
                  disabled
                />
              </SARadioGroup>
              <SAInput
                id="description"
                name="description"
                defaultValue={featureFlag.description}
                label="Description"
                type="text"
                fullWidth
                disabled
              />
              <SATextBox paddingTop={"medium"}>
                <SAText
                  type="standard"
                  text={`Are you sure you want to remove feature flag ${featureFlag.flag_name}?`}
                  colorVariant="alert"
                />
              </SATextBox>
              <SAButton
                fullWidth={false}
                label={"Remove"}
                textTransform="uppercase"
                variant="primary-medium"
                disabled={isSubmitting}
                onClick={onSubmit}
              />
              <SAButton
                fullWidth={false}
                label={"Cancel"}
                textTransform="uppercase"
                variant="primary-medium"
                disabled={isSubmitting}
                onClick={closeHandler}
              />
            </SAColumns>
          </SAInnerBox>
        </SAContainerCard>
      </SAContainerBox>
    </SAModal>
  );
};
