import { createTheme } from "@material-ui/core/styles";

export const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#0173C6',
      light: '#189ecc',
    },
    secondary: {
      main: '#65A100',
    },
    background: {
      default: '#f4f6fa',
    },
  },
  overrides: {
    MuiOutlinedInput: {
      multiline: {
        backgroundColor: 'white',
      },
    },
  },
});
