import { useEffect, useState } from "react";

export interface useTableSearchProps {
  searchVal: string;
  origData: string[][];
}

export const useTableSearch = ({
  searchVal,
  origData,
}: useTableSearchProps) => {
  const [filteredData, setFilteredData] = useState<string[][]>([[""]]);
  const [searchIndex, setSearchIndex] = useState<string[]>(['']);

  useEffect(() => {
    setFilteredData(origData);
    const searchInd = origData.map((featureFlag) => featureFlag.join(" "));
    setSearchIndex(searchInd);
  }, [origData]);

  useEffect(() => {
    if (searchVal) {
      const reqData = searchIndex.map((featureFlag, index) => {
        if  (featureFlag.toLowerCase().indexOf
                  (searchVal.toLowerCase()) >= 0)
          return origData[index];
        return [];
    });
    setFilteredData(reqData.filter(featureFlag => !!featureFlag.length)); 
   } else setFilteredData(origData);
  }, [searchVal, origData, searchIndex]);

  return filteredData;
};
