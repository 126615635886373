import {
  SABox,
  SAButton,
  SACard,
  SAColumns,
  SAIcon,
  SAIcons,
  SAIconSize,
  SAInput,
  SAModal,
  SARadio,
  SARadioGroup,
} from "@saux/design-system-react";
import styled from "styled-components";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { applicationTypes } from "../../pages/FeatureFlagPage";
import { updateFeatureFlag } from "../../services";

const SAButtonBox = styled(SABox)`
  display: flex;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
`;

const SAContainerBox = styled(SABox)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;
const SAInnerBox = styled(SABox)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const SAContainerCard = styled(SACard)`
  width: 100%;
  max-width: 700px;
  display: flex;
`;

const Columns = styled(SAColumns)`
  flex-grow: 1;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  div {
    flex-grow: 0;
  }
`;

export interface UpdateFeatureFlagModalProps {
  featureFlag: any;
  closeHandler: () => void;
  setSuccessText: Dispatch<SetStateAction<string>>;
  setFailureText: Dispatch<SetStateAction<string>>;
  applicationType: applicationTypes;
  getData: () => void;
}

type Inputs = {
  description: string;
};

export const UpdateFeatureFlagModal = ({
  featureFlag,
  closeHandler,
  setSuccessText,
  setFailureText,
  applicationType,
  getData,
}: UpdateFeatureFlagModalProps) => {
  const [enabled, setEnabled] = useState<boolean>(featureFlag.enabled);
  const [description, setDescription] = useState<string>(
    featureFlag.description
  );
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const jsxElement: JSX.Element = (
    <Columns spacing="small">
      <SAIcon
        icon={SAIcons.x}
        size={SAIconSize.medium}
        colorVariant="dark"
        onClick={closeHandler}
      />
    </Columns>
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    criteriaMode: "firstError",
    shouldFocusError: true,
  });
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setSuccessText("");
    setFailureText("");
    setIsSubmitting(true);
    const flag_name = featureFlag.flag_name;
    try {
      const response = await updateFeatureFlag(applicationType, {
        flag: flag_name,
        enabled,
        description,
      });
      if (!response.data.hasErrors) {
        setSuccessText(
          `Successfully Updated Feature Flag ${featureFlag.flag_name}`
        );
      } else {
        setFailureText("Failed to update");
      }
    } catch (err) {
      setFailureText("Failed to update");
    }
    getData();
    setIsSubmitting(false);
    closeHandler();
  };

  useEffect(() => {
    setIsDisabled(
      enabled === featureFlag.enabled && description === featureFlag.description
    );
  }, [enabled, description]);

  return (
    <SAModal onClickHandler={() => {}}>
      <SAContainerBox padding={"large"}>
        <SAContainerCard
          variant="standard"
          title={`Update Feature Flag: ${featureFlag.flag_name}`}
          jsxElement={jsxElement}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <SAInnerBox>
              <SAColumns
                columns={{
                  sm: [4, 8],
                  xs: [12, 12],
                }}
                spacing={{
                  xs: "small",
                }}
              >
                <SARadioGroup
                  id="TrueFalseSelector"
                  label="Status"
                  variant="standard"
                  direction="horizontal"
                  value={`${enabled}`}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setEnabled(e.target.value === "true")
                  }
                >
                  <SARadio value="true" label="True" variant="standard" />
                  <SARadio value="false" label="False" variant="standard" />
                </SARadioGroup>
                <SAInput
                  {...register("description", {
                    required: "Please enter value",
                  })}
                  id="description"
                  name="description"
                  error={Object.prototype.hasOwnProperty.call(
                    errors,
                    "description"
                  )}
                  hint={errors?.description?.message}
                  defaultValue={featureFlag.description}
                  label="Description"
                  type="text"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setDescription(e.target.value)
                  }
                  fullWidth
                />
              </SAColumns>
              <SAButtonBox pt={"medium"}>
                <SAButton
                  fullWidth={false}
                  label={"Save"}
                  textTransform="uppercase"
                  variant="primary-medium"
                  type="submit"
                  disabled={
                    !!errors?.description?.message || isDisabled || isSubmitting
                  }
                />
              </SAButtonBox>
            </SAInnerBox>
          </form>
        </SAContainerCard>
      </SAContainerBox>
    </SAModal>
  );
};
