import React from "react";
import { GlobalStyle, SAUX360Theme } from "@saux/design-system-react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "styled-components";
import App from "./App";
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);
ReactDOM.render(
  <ThemeProvider theme={SAUX360Theme}>
    <GlobalStyle />
    <App />
  </ThemeProvider>,
  document.getElementById("root")
);
