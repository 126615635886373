import {
  SABox,
  SAButton,
  SACard,
  SAColumns,
  SAIcon,
  SAIcons,
  SAIconSize,
  SAModal,
  SAText,
} from "@saux/design-system-react";
import styled from "styled-components";
import { Dispatch, SetStateAction, useState } from "react";
import { removeAssociate } from "../../services";

const SAContainerBox = styled(SABox)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;
const SAInnerBox = styled(SABox)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const SATextBox = styled(SABox)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SAContainerCard = styled(SACard)`
  width: 100%;
  max-width: 700px;
  display: flex;
`;

const Columns = styled(SAColumns)`
  flex-grow: 1;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  div {
    flex-grow: 0;
  }
`;

export interface RemoveAssociateModalProps {
  associate: any;
  closeHandler: () => void;
  setSuccessText: Dispatch<SetStateAction<string>>;
  setFailureText: Dispatch<SetStateAction<string>>;
  getData: () => void;
}

export const RemoveAssociateModal = ({
  associate,
  closeHandler,
  setSuccessText,
  setFailureText,
  getData,
}: RemoveAssociateModalProps) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const jsxElement: JSX.Element = (
    <Columns spacing="small">
      <SAIcon
        icon={SAIcons.x}
        size={SAIconSize.medium}
        colorVariant="dark"
        onClick={closeHandler}
      />
    </Columns>
  );
  const onSubmit = async () => {
    setSuccessText("");
    setFailureText("");
    setIsSubmitting(true);
    try {
      const response = await removeAssociate({ email: associate.email_id });
      if (!response.data.hasErrors) {
        setSuccessText(`Successfully Removed Associate ${associate.email_id}`);
      } else {
        setFailureText("Failed to remove");
      }
    } catch (err) {
      setFailureText("Failed to update");
    }
    getData();
    setIsSubmitting(false);
    closeHandler();
  };
  return (
    <SAModal onClickHandler={() => {}}>
      <SAContainerBox padding={"large"}>
        <SAContainerCard
          variant="standard"
          title={`Remove Associate: ${associate.email_id}`}
          jsxElement={jsxElement}
        >
          <SAInnerBox>
            <SAColumns
              columns={{
                sm: [6, 6, 8, 2, 2],
                xs: [12, 12, 12, 12, 12],
              }}
              spacing={{
                xs: "small",
              }}
            >
              <SAText
                type="standard"
                text={`Empower ID: ${associate.username}`}
              />
              <SAText
                type="standard"
                text={`Email ID: ${associate.email_id}`}
              />
              <SATextBox paddingTop={"medium"}>
                <SAText
                  type="standard"
                  text={`Are you sure you want to remove this associate?`}
                  colorVariant="alert"
                />
              </SATextBox>
              <SAButton
                fullWidth={false}
                label={"Remove"}
                textTransform="uppercase"
                variant="primary-medium"
                disabled={isSubmitting}
                onClick={onSubmit}
              />
              <SAButton
                fullWidth={false}
                label={"Cancel"}
                textTransform="uppercase"
                variant="primary-medium"
                disabled={isSubmitting}
                onClick={closeHandler}
              />
            </SAColumns>
          </SAInnerBox>
        </SAContainerCard>
      </SAContainerBox>
    </SAModal>
  );
};
