import {
  SABox,
  SAButton,
  SACard,
  SAIcon,
  SAIcons,
  SAIconSize,
  SAInput,
  SAPagination,
  SASearch,
  SATable,
} from "@saux/design-system-react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useTableSearch } from "../../hooks";

export interface CardContainerProps {
  title: string;
  buttonLabel: string;
  onButtonClick: (event: any) => void;
  columns: any;
  data: any;
  onRowClick: any;
  onRemoveClick: any;
}
const SAButtonBox = styled(SABox)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const SAContainerBox = styled(SABox)`
  display: flex;
  justify-content: center;
  height: 100%;
`;
const SAContainerCard = styled(SACard)`
  display: flex;
  width: 100%;
  max-width: 1000px;
`;
const SAItemsInput = styled(SAInput)`
  width: 70px;
`;

export const CardContainer = ({
  title,
  onButtonClick,
  buttonLabel,
  columns,
  data,
  onRowClick,
  onRemoveClick,
}: CardContainerProps) => {
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [displayedData, setDisplayedData] = useState<string[][]>([[]]);
  const [searchVal, setSearchVal] = useState<string>("");
  const paginationOnChange = (newCurrentPageIndex: number) => {
    setCurrentPageIndex(newCurrentPageIndex);
  };
  const filteredData = useTableSearch({ searchVal, origData: data });
  const totalItems = filteredData.length;

  const removeButton = (
    <SAButton label="Remove Button" variant="primary-link-small">
      <SAIcon
        icon={SAIcons.trash}
        size={SAIconSize.medium}
        colorVariant="primary"
      />
    </SAButton>
  );
  useEffect(() => {
    if (currentPageIndex * itemsPerPage >= filteredData.length) {
      setCurrentPageIndex(0);
    }
    setDisplayedData(
      filteredData.slice(
        currentPageIndex * itemsPerPage,
        (currentPageIndex + 1) * itemsPerPage
      )
    );
  }, [currentPageIndex, itemsPerPage, filteredData]);

  return (
    <SAContainerBox padding={"large"} display={"flex"}>
      <SAContainerCard variant="standard" title={title}>
        <SABox pb={"medium"}>
          <SASearch
            hint="Press Enter or click on the search icon to search."
            id="searchbar2"
            placeholder="Search"
            onSearch={(search) => setSearchVal(search.toString())}
            variant="search"
            label="example"
            fullWidth
          />
        </SABox>
        <SATable
          columns={columns}
          data={displayedData}
          onRowClick={(
            event: React.MouseEvent<HTMLElement>,
            selectedRowIndex: number
          ) => {
            onRowClick(displayedData[selectedRowIndex]);
          }}
          variant="table-to-listview"
          actionMenuIcon={removeButton}
          onActionMenuClick={(
            event: React.MouseEvent<HTMLElement>,
            selectedRowIndex: number
          ) => {
            event.stopPropagation();
            onRemoveClick(displayedData[selectedRowIndex]);
          }}
        />
        <SAButtonBox padding={"medium"}>
          <SAItemsInput
            id="items_per_page"
            label="Items"
            type="number"
            onChange={(e: any) => setItemsPerPage(e.target.value)}
            defaultValue={itemsPerPage}
          />

          <SAPagination
            currentPageIndex={currentPageIndex}
            totalItems={totalItems}
            itemsPerPage={itemsPerPage}
            onChange={paginationOnChange}
          />

          <SAButton
            fullWidth={false}
            label={buttonLabel}
            onClick={onButtonClick}
            textTransform="uppercase"
            variant="primary-medium"
          />
        </SAButtonBox>
      </SAContainerCard>
    </SAContainerBox>
  );
};
