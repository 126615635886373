import { SAAlert, SABox, SACard, SAText } from "@saux/design-system-react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { AddFeatureFlagModal } from "../components/AddFeatureFlagModal/AddFeatureFlagModal";
import { CardContainer } from "../components/CardContainer/CardContainer";
import { RemoveFeatureFlagModal } from "../components/RemoveFeatureFlag/RemoveFeatureFlag";
import { UpdateFeatureFlagModal } from "../components/UpdateFeatureFlagModal/UpdateFeatureFlagModal";
import { retrieveFeatureFlags } from "../services";
export enum applicationTypes {
  fnol = "fnol",
  claims360 = "claims360",
}
export interface FeatureFlagPageProps {
  applicationType: applicationTypes;
}
export interface FeatureFlagObject {
  application_name: string;
  created_date: string;
  description: string;
  enabled: boolean;
  flag_name: string;
  modified_date: string;
  type: string;
}
const SAContainerBox = styled(SABox)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;
const SAContainerCard = styled(SACard)`
  display: flex;
  width: 100%;
  max-width: 1000px;
`;

export const FeatureFlagPage = ({ applicationType }: FeatureFlagPageProps) => {
  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [successText, setSuccessText] = useState<string>("");
  const [failureText, setFailureText] = useState<string>("");
  const [currentFeatureFlag, setCurrentFeatureFlag] = useState<any | null>(
    null
  );
  const [hasErrors, setHasErrors] = useState<boolean>(false);
  const [featureFlags, setFeatureFlags] = useState<FeatureFlagObject[]>([
    {
      application_name: "null",
      modified_date: "null",
      description: "null",
      enabled: false,
      flag_name: "null",
      created_date: "null",
      type: "null",
    },
  ]);
  const [loading, setLoading] = useState<boolean>(true);

  const getData = async () => {
    try {
      const fetchedData = await retrieveFeatureFlags(applicationType);
      if (fetchedData.data.hasErrors) {
        setHasErrors(true);
      } else {
        setFeatureFlags(fetchedData.data.data);
      }
    } catch (err) {
      setHasErrors(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    "Feature Flag",
    "Description",
    "Enabled",
    "Create Date",
    "Update Date",
  ];
  const data = featureFlags.sort((a, b) =>
    a.flag_name > b.flag_name ? 1 : -1
  );
  const tableData = data.map((featureFlagObject) => {
    return [
      featureFlagObject.flag_name,
      featureFlagObject.description,
      featureFlagObject.enabled ? "True" : "False",
      featureFlagObject.created_date,
      featureFlagObject.modified_date,
    ];
  });

  const checkFlagNameExists = (flagName: string) =>
    !!data.filter((ff) => ff.flag_name === flagName).length;

  const onRowClick = (featureFlagRow: string[]) => {
    setCurrentFeatureFlag(
      featureFlags.find(
        (featureFlagObject) => featureFlagObject.flag_name === featureFlagRow[0]
      )
    );
    setShowUpdateModal(true);
  };
  const onRemoveClick = (featureFlagRow: string[]) => {
    setCurrentFeatureFlag(
      featureFlags.find(
        (featureFlagObject) => featureFlagObject.flag_name === featureFlagRow[0]
      )
    );
    setShowRemoveModal(true);
  };

  const closeUpdateModal = () => {
    setShowUpdateModal(false);
  };
  const closeRemoveModal = () => {
    setShowRemoveModal(false);
  };

  return (
    <>
      {(successText || failureText) && (
        <SAAlert
          severity={successText ? "success" : "error"}
          icon={false}
          closeable
          onClose={() => {
            setFailureText("");
            setSuccessText("");
          }}
        >
          {successText ? successText : failureText}
        </SAAlert>
      )}
      {!hasErrors && !loading && (
        <CardContainer
          title={`${applicationType.toUpperCase()} Feature Flags`}
          buttonLabel={"Add Feature Flag"}
          onButtonClick={() => setShowAddModal(true)}
          columns={columns}
          data={tableData}
          onRowClick={onRowClick}
          onRemoveClick={onRemoveClick}
        />
      )}
      {showUpdateModal && (
        <UpdateFeatureFlagModal
          featureFlag={currentFeatureFlag}
          closeHandler={closeUpdateModal}
          setSuccessText={setSuccessText}
          setFailureText={setFailureText}
          applicationType={applicationType}
          getData={getData}
        />
      )}
      {showRemoveModal && (
        <RemoveFeatureFlagModal
          featureFlag={currentFeatureFlag}
          closeHandler={closeRemoveModal}
          setSuccessText={setSuccessText}
          setFailureText={setFailureText}
          applicationType={applicationType}
          getData={getData}
        />
      )}
      {showAddModal && (
        <AddFeatureFlagModal
          closeHandler={() => setShowAddModal(false)}
          checkFlagNameExists={checkFlagNameExists}
          setSuccessText={setSuccessText}
          setFailureText={setFailureText}
          applicationType={applicationType}
          getData={getData}
        />
      )}
      {hasErrors && (
        <SAContainerBox padding={"large"}>
          <SAContainerCard variant="standard" title="Error">
            <SACard variant="sub">
              <SAText text="Failed to retrieve Feature Flags, please try again later." />
            </SACard>
          </SAContainerCard>
        </SAContainerBox>
      )}
    </>
  );
};
