import {
  SABox,
  SAButton,
  SACard,
  SAColumns,
  SAIcon,
  SAIcons,
  SAIconSize,
  SAInput,
  SAModal,
  SARadio,
  SARadioGroup,
} from "@saux/design-system-react";
import styled from "styled-components";
import { Dispatch, SetStateAction, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { addFeatureFlag } from "../../services";
import { applicationTypes } from "../../pages/FeatureFlagPage";
import React from "react";

const SAButtonBox = styled(SABox)`
  display: flex;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
`;

const SAContainerBox = styled(SABox)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;
const SAInnerBox = styled(SABox)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const SAContainerCard = styled(SACard)`
  width: 100%;
  max-width: 700px;
  display: flex;
`;

const Columns = styled(SAColumns)`
  flex-grow: 1;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  div {
    flex-grow: 0;
  }
`;

export interface AddFeatureFlagModalProps {
  closeHandler: () => void;
  checkFlagNameExists: (flagName: string) => boolean;
  setSuccessText: Dispatch<SetStateAction<string>>;
  setFailureText: Dispatch<SetStateAction<string>>;
  applicationType: applicationTypes;
  getData: () => void;
}

export interface AddFeatureFlagForm {
  flagName: string;
  description: string;
  enabled: boolean;
}
const AddFeatureFlagFormSchema = yup.object().shape({
  flagName: yup.string().required("Flag name is required"),
  description: yup.string().required("Description is required"),
  enabled: yup.boolean().notRequired(),
});

export const AddFeatureFlagModal = ({
  closeHandler,
  checkFlagNameExists,
  setSuccessText,
  setFailureText,
  applicationType,
  getData,
}: AddFeatureFlagModalProps) => {
  const [enabled, setEnabled] = useState<boolean>(true);
  const [description, setDescription] = useState<string>("");
  const [flagName, setFlagName] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [flagNameError, setFlagNameError] = useState<string>("");
  const jsxElement: JSX.Element = (
    <Columns spacing="small">
      <SAIcon
        icon={SAIcons.x}
        size={SAIconSize.medium}
        colorVariant="dark"
        onClick={closeHandler}
      />
    </Columns>
  );
  // @ts-ignore
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AddFeatureFlagForm>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    criteriaMode: "firstError",
    resolver: yupResolver(AddFeatureFlagFormSchema),
    shouldFocusError: true,
  });
  const onSubmit: SubmitHandler<AddFeatureFlagForm> = async (data) => {
    setSuccessText("");
    setFailureText("");
    setIsSubmitting(true);
    try {
      const response: any = await addFeatureFlag(applicationType, {
        flag: flagName,
        enabled,
        description,
        tyoe: "feature_flag",
      });
      if (!response.data.hasErrors) {
        setSuccessText(`Successfully Removed Feature Flag ${flagName}`);
      } else {
        setFailureText("Failed to remove");
      }
    } catch (err) {
      setFailureText("Failed to remove");
    }
    getData();
    setIsSubmitting(false);
    setSuccessText(`Successfully Added Feature Flag ${flagName}`);
    closeHandler();
  };

  return (
    <SAModal onClickHandler={() => {}}>
      <SAContainerBox padding={"large"}>
        <SAContainerCard
          variant="standard"
          title={`Add Feature Flag`}
          jsxElement={jsxElement}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <SAInnerBox>
              <SAColumns
                columns={{
                  sm: [4, 8, 8, 4],
                  xs: [12, 12, 12, 12],
                }}
                spacing={{
                  xs: "small",
                }}
              >
                <SAInput
                  {...register("flagName")}
                  id="flagName"
                  name="flagName"
                  error={
                    Object.prototype.hasOwnProperty.call(errors, "flagName") ||
                    !!flagNameError
                  }
                  hint={errors?.flagName?.message || flagNameError}
                  label="Flag Name"
                  type="text"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setFlagName(e.target.value);
                    checkFlagNameExists(e.target.value)
                      ? setFlagNameError("Flag Name Already Exists")
                      : setFlagNameError("");
                  }}
                  fullWidth
                />
                <SAInput
                  {...register("description")}
                  id="description"
                  name="description"
                  error={Object.prototype.hasOwnProperty.call(
                    errors,
                    "description"
                  )}
                  hint={errors?.description?.message}
                  label="Description"
                  type="text"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setDescription(e.target.value)
                  }
                  fullWidth
                />
                <SARadioGroup
                  id="enabled"
                  label="Status"
                  variant="standard"
                  direction="horizontal"
                  value={`${enabled}`}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setEnabled(e.target.value === "true");
                  }}
                >
                  <SARadio value="true" label="True" variant="standard" />
                  <SARadio value="false" label="False" variant="standard" />
                </SARadioGroup>
                <SAButtonBox pt={"medium"}>
                  <SAButton
                    fullWidth={false}
                    label={"Save"}
                    textTransform="uppercase"
                    variant="primary-medium"
                    type="submit"
                    disabled={
                      !!errors?.description?.message ||
                      !!errors?.flagName?.message ||
                      !!flagNameError ||
                      isSubmitting
                    }
                  />
                </SAButtonBox>
              </SAColumns>
            </SAInnerBox>
          </form>
        </SAContainerCard>
      </SAContainerBox>
    </SAModal>
  );
};
