/* eslint-disable no-useless-escape */
import * as yup from "yup";

export const emailValidationMessage = "Enter as example@stateauto.com";
export const empIdValidationMessage = "Enter as xyz1234";

export const emailValidation = (excludeEmptyString: boolean) => {
  return yup
    .string()
    .matches(
      /^(?![-._%+])[a-zA-Z0-9._%+-]+(?:[\\.||\\-][a-zA-Z0-9]+)*@stateauto.com/,
      {
        message: emailValidationMessage,
        excludeEmptyString,
      }
    );
};

export const empIdValidation = (excludeEmptyString: boolean) => {
  return yup.string().matches(/\S{3}\d{4}/, {
    message: empIdValidationMessage,
    excludeEmptyString,
  });
};
