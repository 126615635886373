import { useEffect, useState } from "react";
import { SAHeader } from "./components/Header/Header";
import styled, { ThemeProvider as SAUXThemeProvider } from "styled-components";
import { SABox, SACard, SAUX360Theme } from "@saux/design-system-react";
import { Container, makeStyles, ThemeProvider } from "@material-ui/core";
import { defaultTheme } from "./themes/default.theme";
import { Router, RouteComponentProps } from "@reach/router";
import { ASSOCIATE_ROUTE, CLAIMS360_ROUTE, ROOT_ROUTE } from "./routes/paths";
import { applicationTypes, FeatureFlagPage } from "./pages/FeatureFlagPage";
import { AssociatePage } from "./pages/AssociatePage";
import { RefreshToken, SAAuth } from "./pages/utils/auth";
import Cookies from "js-cookie";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import path from "path";
import { BaseSwitch } from "./components/BaseSwitch/BaseSwitch";

const useStyles = makeStyles({
  root: {
    height: "100%",
    width: "100%",
  },
});
const SAContainerBox = styled(SABox)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;
const SAContainerCard = styled(SACard)`
  display: flex;
  width: 100%;
  max-width: 1000px;
`;
const ScrollToTop = ({ children }: any) => {
  useEffect(
    () =>
      window.scrollTo({
        top: 0,
      }),
    []
  );
  return children;
};

const WithScrollToTop = ({ children }: any) => (
  <ScrollToTop>{children}</ScrollToTop>
);

const RouteContainer = ({ children }: any) => {
  const classes = useStyles();

  return (
    <WithScrollToTop>
      <Container
        disableGutters
        maxWidth={false}
        classes={{ root: classes.root }}
      >
        {children}
      </Container>
    </WithScrollToTop>
  );
};

const FNOLPageRoute = (_: RouteComponentProps) => (
  <RouteContainer>
    <FeatureFlagPage applicationType={applicationTypes.fnol} />
  </RouteContainer>
);
const Claims360PageRoute = (_: RouteComponentProps) => (
  <RouteContainer>
    <FeatureFlagPage applicationType={applicationTypes.claims360} />
  </RouteContainer>
);
const AssociatePageRoute = (_: RouteComponentProps) => (
  <RouteContainer>
    <AssociatePage />
  </RouteContainer>
);

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isSignedIn, setIsSignedIn] = useState<boolean>(false);
  useEffect(() => {
    // try to get a cached user session
    SAAuth.currentSession()
      .then(async (session: CognitoUserSession & RefreshToken) => {
        Cookies.set("userToken", session?.idToken?.jwtToken);
        setIsSignedIn(true);
      })
      .catch(() => {
        Cookies.set("authenticated-redirect", "/");
        SAAuth.signIn().catch(() => {});
      });
  }, []);
  return (
    <>
      <ThemeProvider theme={defaultTheme}>
        <SAUXThemeProvider theme={SAUX360Theme}>
          <SAHeader />
          {isSignedIn && (
            <Router>
              <FNOLPageRoute path={ROOT_ROUTE} />
              <Claims360PageRoute path={CLAIMS360_ROUTE} />
              <AssociatePageRoute path={ASSOCIATE_ROUTE} />
            </Router>
          )}
        </SAUXThemeProvider>
      </ThemeProvider>
    </>
  );
};

export default App;
