import saLogo from "../../assets/images/StateAuto-logo.svg";
import { AppBar, Box } from "@material-ui/core";
import { SABox, SAButton } from "@saux/design-system-react";
import styled from "styled-components";
import { navigate } from "@reach/router";
import {
  ASSOCIATE_ROUTE,
  CLAIMS360_ROUTE,
  ROOT_ROUTE,
} from "../../routes/paths";
import React from "react";

const Container = styled(SABox)`
  justify-content: space-between;
  align-items: center;
`;
const ButtonContainer = styled(SABox)`
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const SAHeader = () => {
  return (
    <AppBar position="static">
      <Container display={"flex"}>
        <Box p={3}>
          <img src={saLogo} alt="" />
        </Box>
        <ButtonContainer>
          <SAButton
            fullWidth={false}
            label="FNOL"
            onClick={() => navigate(ROOT_ROUTE)}
            textTransform="uppercase"
            variant="secondary-large"
          />
          <SAButton
            fullWidth={false}
            label="Claims360"
            onClick={() => navigate(CLAIMS360_ROUTE)}
            textTransform="uppercase"
            variant="secondary-large"
          />
          <SAButton
            fullWidth={false}
            label="Associate"
            onClick={() => navigate(ASSOCIATE_ROUTE)}
            textTransform="uppercase"
            variant="secondary-large"
          />
        </ButtonContainer>
      </Container>
    </AppBar>
  );
};
