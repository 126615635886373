import {
  SABox,
  SAButton,
  SACard,
  SAColumns,
  SAIcon,
  SAIcons,
  SAIconSize,
  SAInput,
  SAModal,
} from "@saux/design-system-react";
import styled from "styled-components";
import { Dispatch, SetStateAction, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { emailValidation, empIdValidation } from "../../validations";
import { addAssociate } from "../../services";
import React from "react";

const SAButtonBox = styled(SABox)`
  display: flex;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
`;

const SAContainerBox = styled(SABox)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;
const SAInnerBox = styled(SABox)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const SAContainerCard = styled(SACard)`
  width: 100%;
  max-width: 700px;
  display: flex;
`;

const Columns = styled(SAColumns)`
  flex-grow: 1;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  div {
    flex-grow: 0;
  }
`;

export interface AddAssociateModalProps {
  closeHandler: () => void;
  setSuccessText: Dispatch<SetStateAction<string>>;
  setFailureText: Dispatch<SetStateAction<string>>;
  checkIsEmailExisting: any;
  checkIsEmpIdExisting: any;
  getData: () => void;
}

export interface AddAssociateForm {
  email: string;
  empId: string;
}
const AddAssociateFormSchema = yup.object().shape({
  email: emailValidation(true).required("Email address is required"),
  empId: empIdValidation(true),
});

export const AddAssociateModal = ({
  closeHandler,
  setSuccessText,
  setFailureText,
  checkIsEmailExisting,
  checkIsEmpIdExisting,
  getData,
}: AddAssociateModalProps) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isSuccessfull, setIsSuccessfull] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [empId, setEmpId] = useState<string>("");
  const [emailNameError, setEmailNameError] = useState<string>("");
  const [empIdError, setEmpIdError] = useState<string>("");

  const jsxElement: JSX.Element = (
    <Columns spacing="small">
      {isSuccessfull && `Associate was added successfully`}
      <SAIcon
        icon={SAIcons.x}
        size={SAIconSize.medium}
        colorVariant="dark"
        onClick={closeHandler}
      />
    </Columns>
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AddAssociateForm>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    criteriaMode: "firstError",
    resolver: yupResolver(AddAssociateFormSchema),
    shouldFocusError: true,
  });
  const onSubmit: SubmitHandler<AddAssociateForm> = async () => {
    setIsSubmitting(true);
    try {
      const response: any = await addAssociate({ email, username: empId });
      if (!response.data.hasErrors) {
        setSuccessText(`Successfully Added Associate ${email}`);
      } else {
        setFailureText("Failed to add");
      }
    } catch (err) {
      setFailureText("Failed to add");
    }
    getData();
    setIsSubmitting(false);
    setIsSuccessfull(true);
    setSuccessText(`Successfully Added Associate ${email}`);
    closeHandler();
  };

  return (
    <SAModal onClickHandler={() => {}}>
      <SAContainerBox padding={"large"}>
        <SAContainerCard
          variant="standard"
          title={`Add Associate`}
          jsxElement={jsxElement}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <SAInnerBox>
              <SAInput
                {...register("email")}
                id="email"
                name="email"
                error={
                  Object.prototype.hasOwnProperty.call(errors, "email") ||
                  !!emailNameError
                }
                hint={
                  errors?.email?.message ||
                  emailNameError ||
                  "Please enter the email address of the associate"
                }
                label="Email Address"
                type="text"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setEmail(e.target.value);
                  checkIsEmailExisting(e.target.value)
                    ? setEmailNameError("Email Already Exists")
                    : setEmailNameError("");
                }}
                fullWidth
              />
              <SAInput
                {...register("empId")}
                id="empId"
                name="empId"
                error={
                  Object.prototype.hasOwnProperty.call(errors, "empId") ||
                  !!empIdError
                }
                hint={
                  errors?.empId?.message ||
                  empIdError ||
                  "Please enter the username of the associate"
                }
                label="Username"
                type="text"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setEmpId(e.target.value);
                  checkIsEmpIdExisting(e.target.value)
                    ? setEmpIdError("Username Already Exists")
                    : setEmpIdError("");
                }}
                fullWidth
              />
              <SAButtonBox pt={"medium"}>
                <SAButton
                  fullWidth={false}
                  label={"Save"}
                  textTransform="uppercase"
                  variant="primary-medium"
                  type="submit"
                  disabled={
                    !!errors?.email?.message || !!emailNameError || isSubmitting
                  }
                />
              </SAButtonBox>
            </SAInnerBox>
          </form>
        </SAContainerCard>
      </SAContainerBox>
    </SAModal>
  );
};
